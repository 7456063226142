<template>
  <section id="bookkeeping">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="4" md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search..." @keyup.enter="searchInvoice" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showInvoices" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Invoices
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-invoices" />
          </h4>
          <b-popover target="popover-invoices" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="invoicesTable.currentPage"
          :items="invoicesTable.items" :fields="invoicesTable.fields" :sort-by.sync="invoicesTable.sortBy"
          :sort-desc.sync="invoicesTable.sortDesc" :sort-direction="invoicesTable.sortDirection"
          :filter="invoicesTable.filter" :filter-included-fields="invoicesTable.filterOn"
          @sort-changed="sortChangedInvoices" @row-clicked="invoicesTableRowClicked">
          <template #cell(pdf_invoices)="data">
            <b-badge v-for="(item, index) in data.item.pdf_invoices" :key="index" variant="light-primary">
              <div v-if="item.pk">
                {{ item.filename }}
              </div>
            </b-badge>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="invoicesTable.currentPage" :total-rows="invoicesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleInvoicesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="invoice-details-modal" size="xl" title="Invoice Details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showInvoiceDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Invoice positions
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-invoice-positions" />
            </h4>
            <b-popover target="popover-invoice-positions" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table v-if="showInvoicePossTable" striped hover responsive class="position-relative"
            :current-page="invoicePossTable.currentPage" :items="invoicePossTable.items"
            :fields="invoicePossTable.fields" :sort-by.sync="invoicePossTable.sortBy"
            :sort-desc.sync="invoicePossTable.sortDesc" :sort-direction="invoicePossTable.sortDirection" />
        </b-card>
      </b-overlay>
      <b-overlay :show="showPDFInvoiceDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <div v-if="showPDFInvoiceFile">
          <hr>
          <embed :src="PDFInvoiceFile" width="100%" height="800px">
        </div>
      </b-overlay>
    </b-modal>
    <b-overlay :show="showPayments" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Payments
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-payments" />
          </h4>
          <b-popover target="popover-payments" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="paymentsTable.currentPage"
          :items="paymentsTable.items" :fields="paymentsTable.fields" :sort-by.sync="paymentsTable.sortBy"
          :sort-desc.sync="paymentsTable.sortDesc" :sort-direction="paymentsTable.sortDirection"
          :filter="paymentsTable.filter" :filter-included-fields="paymentsTable.filterOn"
          @sort-changed="sortChangedPayments" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="paymentsTable.currentPage" :total-rows="paymentsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handlePaymentsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showVorInvoices" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            VOR invoices (scraped data)
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-vor-invoices" />
          </h4>
          <b-popover target="popover-vor-invoices" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportVorInvoicesTable" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="vorInvoicesTable.currentPage"
          :items="vorInvoicesTable.items" :fields="vorInvoicesTable.fields" :sort-by.sync="vorInvoicesTable.sortBy"
          :sort-desc.sync="vorInvoicesTable.sortDesc" :sort-direction="vorInvoicesTable.sortDirection"
          :filter="vorInvoicesTable.filter" :filter-included-fields="vorInvoicesTable.filterOn"
          @sort-changed="sortChangedVorInvoices" @row-clicked="vorInvoicesTableRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="vorInvoicesTable.currentPage" :total-rows="vorInvoicesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleVorInvoicesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="vor-invoice-details-modal" size="xl" title="Vor Invoice Details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showVorInvoiceDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              VOR invoice positions
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                id="popover-vor-invoice-positions" />
            </h4>
            <b-popover target="popover-vor-invoice-positions" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table v-if="showVorInvoicePossTable" striped hover responsive class="position-relative"
            :current-page="vorInvoicePossTable.currentPage" :items="vorInvoicePossTable.items"
            :fields="vorInvoicePossTable.fields" :sort-by.sync="vorInvoicePossTable.sortBy"
            :sort-desc.sync="vorInvoicePossTable.sortDesc" :sort-direction="vorInvoicePossTable.sortDirection" />
        </b-card>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BBadge,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
  BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BBadge,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showInvoices: true,
      showPayments: true,
      showVorInvoices: true,
      showInvoiceDetails: true,
      showPDFInvoiceDetails: true,
      showVorInvoiceDetails: true,
      showInvoicePossTable: false,
      showVorInvoicePossTable: false,
      showPDFInvoiceFile: false,
      showExportStart: false,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      PDFInvoiceFile: '',
      queryParamsInvoices: {},
      queryParamsPayments: {},
      queryParamsVorInvoices: {},
      invoicesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'document_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'invoice_no', label: 'invoice no.', sortable: true },
          { key: 'order_no', label: 'order no.', sortable: true },
          { key: 'supinvoice_no', label: 'supplier invoice no.', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'expenses',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_amount',
            label: 'payment amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_date',
            label: 'payment date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'document_date',
            label: 'document date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'payment_term',
            label: 'payment term',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'status', label: 'status', sortable: true },
          { key: 'pdf_invoices', label: 'pdf invoices', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      paymentsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'publication_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'payment_id', label: 'id', sortable: true },
          { key: 'company_name', label: 'company name', sortable: true },
          { key: 'assignment', label: 'assignment', sortable: true },
          {
            key: 'total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'publication_date',
            label: 'publication date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      vorInvoicesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'document_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'invoice_no', label: 'invoice no.', sortable: true },
          { key: 'order_no', label: 'order no.', sortable: true },
          { key: 'supinvoice_no', label: 'supplier invoice no.', sortable: true },
          {
            key: 'total_with_vat',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'open_amount',
            label: 'open amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_amount',
            label: 'payment amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_date',
            label: 'payment date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'document_date',
            label: 'document date',
            sortable: true,
          },
          {
            key: 'payment_term',
            label: 'payment term',
            sortable: true,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      invoicePossTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'item_sku',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'item_sku', label: 'item sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'extra_cost',
            label: 'extra cost',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'tax',
            label: 'tax',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'tax_amount',
            label: 'tax amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      vorInvoicePossTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'item_sku',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'item_sku', label: 'item sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsInvoices.from_date = dateRange[0];
    this.queryParamsInvoices.to_date = dateRange[1];
    this.queryParamsPayments.from_date = dateRange[0];
    this.queryParamsPayments.to_date = dateRange[1];
    this.queryParamsVorInvoices.from_date = dateRange[0];
    this.queryParamsVorInvoices.to_date = dateRange[1];

    try {
      await this.getInvoices();
      await this.getPayments();
      await this.getVorInvoices();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getInvoices() {
      this.showInvoices = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/invoices/`, this.queryParamsInvoices);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.invoicesTable.items = results;
          this.invoicesTable.totalRows = results[0].count * 2;
        }
        else {
          this.invoicesTable.items = [];
          this.invoicesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInvoices = false;
      }
    },
    async getPayments() {
      this.showPayments = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/payments/`, this.queryParamsPayments);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.paymentsTable.items = results;
          this.paymentsTable.totalRows = results[0].count * 2;
        }
        else {
          this.paymentsTable.items = [];
          this.paymentsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPayments = false;
      }
    },
    async getVorInvoices() {
      this.showVorInvoices = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/vor-invoices/`, this.queryParamsVorInvoices);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.vorInvoicesTable.items = results;
          this.vorInvoicesTable.totalRows = results[0].count * 2;
        }
        else {
          this.vorInvoicesTable.items = [];
          this.vorInvoicesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showVorInvoices = false;
      }
    },
    async getInvoicePoss(invoiceNo) {
      this.showInvoiceDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/invoice/`, { invoice_no: invoiceNo });
        this.invoicePossTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInvoiceDetails = false;
        this.showInvoicePossTable = true;
      }
    },
    async getPDFInvoice(PDFInvoiceID) {
      this.showPDFInvoiceDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/pdf-invoices/${PDFInvoiceID}/`, {});
        this.PDFInvoiceFile = response.data.encoded_pdf;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPDFInvoiceDetails = false;
      }
    },
    async getVorInvoicePoss(supinvoiceNo) {
      this.showPDFInvoiceDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/vor-invoice/`, { supinvoice_no: supinvoiceNo });
        this.vorInvoicePossTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showVorInvoiceDetails = false;
        this.showVorInvoicePossTable = true;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsInvoices.from_date = dateRange[0];
        this.queryParamsInvoices.to_date = dateRange[1];
        this.queryParamsPayments.from_date = dateRange[0];
        this.queryParamsPayments.to_date = dateRange[1];
        this.queryParamsVorInvoices.from_date = dateRange[0];
        this.queryParamsVorInvoices.to_date = dateRange[1];
        await this.getInvoices();
        await this.getPayments();
        await this.getVorInvoices();
        this.oldDateRange = this.dateRange;
      }
    },
    async searchInvoice() {
      this.queryParamsInvoices.page = 1;
      this.queryParamsInvoices.search = '%' + this.searchValue.toLowerCase() + '%';
      this.queryParamsPayments.page = 1;
      this.queryParamsPayments.search = '%' + this.searchValue.toLowerCase() + '%';
      this.queryParamsVorInvoices.page = 1;
      this.queryParamsVorInvoices.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getInvoices();
      await this.getPayments();
      await this.getVorInvoices();
    },
    async sortChangedInvoices(value) {
      if (value.sortDesc === true) {
        this.queryParamsInvoices.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsInvoices.ordering = value.sortBy;
      }
      await this.getInvoices();
    },
    async sortChangedPayments(value) {
      if (value.sortDesc === true) {
        this.queryParamsPayments.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsPayments.ordering = value.sortBy;
      }
      await this.getPayments();
    },
    async sortChangedVorInvoices(value) {
      if (value.sortDesc === true) {
        this.queryParamsVorInvoices.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsVorInvoices.ordering = value.sortBy;
      }
      await this.getVorInvoices();
    },
    async handleInvoicesTablePageChange(value) {
      this.queryParamsInvoices.page = value;
      await this.getInvoices();
    },
    async handlePaymentsTablePageChange(value) {
      this.queryParamsPayments.page = value;
      await this.getPayments();
    },
    async handleVorInvoicesTablePageChange(value) {
      this.queryParamsVorInvoices.page = value;
      await this.getVorInvoices();
    },
    async invoicesTableRowClicked(row) {
      this.PDFInvoiceFile = '';
      this.getInvoicePoss(row.invoice_no);
      if (row.pdf_invoices.length > 0 && row.pdf_invoices[0].pk !== null) {
        await this.getPDFInvoice(row.pdf_invoices[0].pk);
        this.showPDFInvoiceFile = true;
      } else {
        this.showPDFInvoiceDetails = false;
        this.showPDFInvoiceFile = false;
      }
      this.$refs['invoice-details-modal'].show();
    },
    async vorInvoicesTableRowClicked(row) {
      await this.getVorInvoicePoss(row.supinvoice_no);
      this.$refs['vor-invoice-details-modal'].show();
    },
    async exportVorInvoicesTable() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-vor-invoices/`, this.queryParamsVorInvoices);
        const exportedData = response.data.results;
        this.showExportStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'VorInvoices.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
